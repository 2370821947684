* {
  box-sizing: border-box;
  font-family: MessinaSans;
  margin: 0;
  padding: 0;
}

:root {
overflow-x: hidden;
}

body{
  margin: 0;
  font-family: MessinaSans;
  overflow-x: hidden;
}
@font-face {
  font-family: 'MessinaSans';
  src: local('MessinaSans'), url(./fonts/MessinaSans-Regular.otf) format('truetype');
}
