.container-2 {
  height: 100%;
}

.container-2 div {
  flex-basis: 50%;
  height: 460px;
}

.logo{
  z-index: 1;
    position: absolute;
    top: 30px;
    height: 75px;
    width: 50px;
    margin-left: 10px;

}

.pill-button {
    height: 42px;
    letter-spacing: 0.61px;
    font-family: MessinaSans;
    display: inline-block;
    padding: 6px 16px;
    border: 2px solid #1E72FF;
    border-radius: 50px; 
    background-color: white;
    color: #1E72FF;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.pill-button:hover {
  background-color: #1E72FF;
  color: white;
}

.side-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.inside2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 25px;
}

.image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.title{
  height: 21px;
  color: #323232;
  font-family: MessinaSans;
}

.fullName {
  display: block;
  width: 295px;
  color: #323232;
  font-family: "Tiempos Headline";
}


