@media (max-width: 767px) and (min-width: 300px) {
    .menu-wrap .toggler:checked ~ .menu {
        width: 100%;
    }
    .container-2 div{
        align-items: center;
    }
    .inside2{
        text-align: center;
    }
}

@media (min-width: 300px) {
    .menu-wrap .hamburger > div{
        background-color: #585757;
    }

  .text-container {
      flex-direction: column-reverse;
  }
  .img-div{
      margin-bottom: 50px;
  }
  /*   Card => middle container    */
  .top-left{
      font-size: 30px;
  }
  .top-p{
      font-size: 50px;
      line-height: 43px;
  }
  .lower-p{
      font-size: 30px;
      white-space: nowrap;
  }
  .fullName{
      font-size: 100px;
      letter-spacing: -0.75px;
      line-height: 103px;
  }
  .title{
      font-size: 18px;
      letter-spacing: 0.82px;
      line-height: 21px;
      white-space: nowrap;
  }
  .button{
      width: 165px;
  }
  .text{
      left: 15px;
  }
  /**** Cards *****/
  .top-left{
      top: 3%;
      left: 3%;
  }
}

@media (min-width: 768px) {
    .menu-wrap .hamburger > div{
        background-color: black;
    }
  .container-2{
      display: flex;
  }
  .contained{
      display: flex;
  }
  .row {
      padding: 0 4px 50px;
  }
  /*   Card => middle container    */
  .top-p{
      font-size: 20px;
      line-height: 80px;
  }
  .lower-p{
      font-size: 15px;
      height: 20px;
  }
  .top-left{
      top: 15px;
      left: 15px;
      font-size: 13px;
  }
    .main-container {
    overflow-x: hidden;
  }
  .overlay{
      opacity: 0;
  }

}


@media (min-width: 1024px) { 
      /*   Card => middle container    */
      .top-p{
          font-size: 30px;
          letter-spacing: 1px;
          line-height: 68px;
      }
      .lower-p{
          font-size: 16px;
          letter-spacing: 2px;
          line-height: 19px;
      }
      .top-left{
          font-size: 13px;
          letter-spacing: 4.88px;
          line-height: 15px;
          top: 20px;
          left: 20px;
      }
      .text{
          left:20px;
      }
      .text-container{
          flex-direction: row;
          margin-right: 45px;
      }
}


@media (min-width: 1400px) {
      /*   Card => middle container    */
      .top-left{
          font-size: 16px;
          letter-spacing: 6px;
          line-height: 19px;
      }
      .top-p{
          font-size: 36px;
          letter-spacing: 1px;
          line-height: 43px;
      }
      .lower-p{
          font-size: 16px;
          letter-spacing: 2px;
          line-height: 19px;
      }
      .fullName{
          font-size: 133px;
          letter-spacing: -1px;
          line-height: 132px;
      }
      .title{
          font-size: 22px;
          letter-spacing: 1px;
          line-height: 26px;
      }
      .button{
          width: 252px;
      }
}