footer {
  display: flex;
  bottom: 0;
  background: #1A1A1A;
  color: white;
}

.foot-content{
  display: flex;
  width: 100%;
}

.leftFooter, .rightFooter{
  flex-basis: 50%;
}

a {
  color: white;
}

a:hover {
  text-decoration: none;
}

#footSpan {
  height: 20px;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 2.49px;
  line-height: 20px;
}

.right-links, .left-links{
  color: #1E72FF;
  font-family: Helvetica;
  font-size: 30px;
  letter-spacing: 3.58px;
  line-height: 36px;
  height: 29.8px;
  text-transform: uppercase;
}

@media (max-width: 499px){
  footer {
    height: 350px;
  }
  .foot-content {
    flex-direction: column;
    align-items: baseline;
  }
  .leftFooter {
    padding: 40px 0 0 20px;
  }
  .rightFooter {
    padding: 20px 0 0 20px;
  }
}
@media (max-width: 644px){
  .right-links {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 500px){
  .foot-content{
    flex-direction: row;
    align-items: center;
  }
  .leftFooter{
    padding: 0 0 25px 45px;
  }
  .rightFooter{
    text-align: right;
    padding: 0 45px 25px 0;
  }
  footer{
    height: 195px;
  }
}
@media (max-width: 767px){
  .left-links {
    display: flex;
    flex-direction: column;
  }
  .right-links{
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 768px) {
  footer{
    height: 188px;
  }
  .left-links {
    display: flex;
    flex-direction: row;
  }
  .right-links {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .linkedin-foot{
    padding-right: 20px;
  }
  .contact-foot{
    padding-left: 20px;
  }
}

