.items {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #000E8F;
  font-family: MessinaSans;
  margin-bottom: 115px;
}

.head {
    font-size: 50px;
    text-transform: uppercase;
    padding-top: 150px;
}

.mail {
  font-size: 24px;
  padding: 60px 0 10px 0;
}

.sites {
  font-size: 24px;
  padding: 60px;
  display: flex;
  list-style: none;
  font-family: MessinaSans;
}

.wrapper footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  background: #1A1A1A;
  color: white;
  z-index: 100;
}