.boxed {
    position: relative;
    width: 100%;
}

.top-p {
    height: 43px;
    width: 311px;
    color: #FFFFFF;
    font-family: MessinaSans;
    font-weight: 600;
    letter-spacing: 1px;
}

.lower-p {
    width: 275px;
    color: #FFFFFF;
    font-family: "Tiempos Headline";
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    /*opacity: 0;*/
    transition: .5s ease;
}

.boxed:hover .overlay {
    opacity: 1;
}

.boxed:hover .travel {
    background-color: #730411;
    opacity: 0.85;
}

.boxed:hover .alcohol {
    background-color: #D99D19;
    opacity: 0.85;
}

.boxed:hover .cats {
    background-color: #D59897;
    opacity: 0.85;
}

.boxed:hover .social {
    background-color: #95613D;
    opacity: 0.85;
}

.text {
    position: absolute;
    bottom: 8px;
}

.top-left {
    position: absolute;
    writing-mode: vertical-rl;
    z-index: 1;
    color: #FFFFFF;
    font-family: MessinaSans;
    font-weight: bold;
}
