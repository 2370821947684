/*positions menu*/
.menu-wrap {
    position: fixed;
    top: 0;
    right: 60px;
    z-index: 2;
}
/*sizes checkbox*/
.menu-wrap .toggler {
    position: absolute;
    top: 5px;
    left: 25px;
    z-index: 2;
    cursor: pointer;
    width: 15px;
    height: 50px;
    opacity: 0; /*makes checkbox disappear*/
}
/*hamburger div inside checkbox*/
.menu-wrap .hamburger {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 60px;
    height: 60px;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
/*lines inside hamburger div
middle line
get immediate div (1st div) using > sign*/
.menu-wrap .hamburger > div {
    position: relative;
    width: 100%;
    height: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.4s ease;
}
/*Top n bottom lines*/
.menu-wrap .hamburger > div:before,
.menu-wrap .hamburger > div:after {
    content: '';
    position: absolute;
    z-index: 1;
    top: -10px;
    width: 100%;
    height: 2px;
    background: inherit;
}
/*moves bottom line down from center line*/
.menu-wrap .hamburger > div:after {
    top: 10px;
}
/*Animates toggler by rotating lines*/
.menu-wrap .toggler:checked + .hamburger > div {
    transform: rotate(135deg);
    background-color: white;
}
/*turns lines to X*/
.menu-wrap .toggler:checked + .hamburger > div:before,
.menu-wrap .toggler:checked + .hamburger > div:after {
    top: 0;
    transform: rotate(90deg);
}
/*rotate on hover when checked=true*/
.menu-wrap .toggler:checked:hover + .hamburger > div {
    transform: rotate(225deg);
}
/*shows menu
~ chooses element to target*/
.menu-wrap .toggler:checked ~ .menu {
    visibility: visible;
}
/*show menu
target 1st div in menu*/
.menu-wrap .toggler:checked ~ .menu > div {
    transform: translate(10px, 10px);
    transition-duration: 0.75s; /*controls menu opening speed*/
}
/*show menu
target inner most div w/ link items*/
.menu-wrap .toggler:checked ~ .menu > div > div {
    opacity: 1;
    transition: opacity 0.4s ease;
}
/*positions menu items to center page(side menu)*/
.menu-wrap .menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    visibility: hidden;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
/*targets 1st div in menu*/
.menu-wrap .menu > div {
    background: rgba(24, 39, 51, 1);
    width: 200vw;
    height: 200vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: none;
    transform: scale(0); /*makes menu disappear*/
    transition: all 0.4s ease;
}
/*targets 2nd div*/
.menu-wrap .menu > div > div {
    text-align: center;
    max-width: 90vw;
    max-height: 100vh;
    opacity: 0;
    transition: opacity 0.4s ease;
}
/*target list items*/
.menu-wrap .menu > div > div > ul > li {
    list-style: none;
    color: white;
    font-size: 1.5rem;
    padding: 1rem;
}

.menu-wrap .menu > div > div > ul > li > a {
    color: inherit; /*change color of text items*/
    text-decoration: none;
    transition: color 0.4s ease;
    font-family: MessinaSans;
}

/* .menu-wrap .menu > div > div > ul > li > a.active {  //this code is when i want to activate active state on the menu
    border-bottom: 4px solid #1E72FF; 
} */

.menu-wrap .menu > div > div > ul > li > a:hover {
    border-bottom: 4px solid #1E72FF; 
}