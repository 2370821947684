.text-container{
    display: flex;
    /* margin-bottom: 114px; */
}

.img-div {
    flex-basis: 40%;
}

.txt-div {
    flex-basis: 60%;
}

.about-para {
    margin: 0 200px 0 75px;
    font-family: MessinaSans;
    font-size: larger;
    padding-bottom: 25px;

}

.btn-div {
    display: flex;
    margin: 48px 0 0px 75px;
}

.title-para {
    margin: 150px 0px 0px 75px;
    height: 48px;
    width: 391px;
    color: #A1A2A4;
    font-family: MessinaSans;
    font-size: 21px;
    font-weight: 600;
    letter-spacing: 4px;
    line-height: 25px;
}

.img-div .image {
    display: block;
    width: 100%;
    height: 90%;
    object-fit: cover;
    border-radius: 50px;
}